export const common = {
  Test: 'テスト',
  Save: '店',
  Add: '追加',
  Edit: '編集',
  View: 'ビュー',
  Return: '戻る',
  Cancel: 'あきらめる',
  Cancel2: 'キャンセル',
  Cancel_return: '$t(Return)',
  Remove: '消去',
  Contact: 'アドバイザーに連絡する',
  Delete: '消去',
  Leave: '離れる',
  Confirm: '確認する',
};
