import * as trackerKinds from 'constants/trackerKinds';
import * as featureKeys from 'constants/featureKeys';

export const main = {
  'App Description':
    'Google アナリティクス、Google タグ マネージャー、Facebook 広告コンバージョン トラッキングなどのサードパーティの追跡ツールを使用すると、ストア内での顧客のアクティビティを追跡するのに役立ちます。トラッキングデータを活用して分析することは、ネットストアの集客増加やマーケティング戦略の立案にとても役立ちます！',
  'Add Tracker': '顧客のアクティビティ追跡に参加する',
  EVENT_TRACKER_TABLE: {
    KIND: '追跡ツール',
    EVENT_TYPES: 'イベントを追跡する',
    [`EVENT_TYPES_${featureKeys.LINE_POINTS}`]:
      'イベント/アクティビティを追跡する',
    CODE: '追跡番号/名前',
    ACTIONS: '',
  },
  docLink:
    'https://support.shoplineapp.com/hc/en-us/articles/204215529-Google-Analytics-Setup',
  [`docLink_${trackerKinds.GA}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/204215529-Google-Analytics-Setup',
  [`docLink_${trackerKinds.GTM}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/205276289-Google-Tag-Manager-Setup',
  [`docLink_${trackerKinds.G_ADS}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/205293995-Google-Ads-Conversion-Tracker-Setup',
  [`docLink_${trackerKinds.G_REMARKETING}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/205059449-Google-Remarketing-Tag-Setup',
  [`docLink_${trackerKinds.BING}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/206305016-Bing-Ads-Conversion-Tracker-Setup',
  [`docLink_${trackerKinds.YAHOO}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/206651403-Yahoo-Gemini-Native-Ad-Tracking-Code',
  [`docLink_${trackerKinds.META}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/360042210731-Facebook-Business-Extension-',
  [`docLink_${trackerKinds.LINE}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/6055618128409-LINE-Ads-Tag-LAP-Ads-Event-Tracker-Settings',
  [`docLink_${trackerKinds.LINE_POINTS}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/13172197373721',
  [`docLink_${trackerKinds.GA4}`]:
    'https://support.shoplineapp.com/hc/en-us/articles/8646049467801',
  docLink_gaECommerce:
    'https://support.shoplineapp.com/hc/en-us/articles/207582673-Google-Analytics-Ecommerce-Setup',
  docLink_gAdsEnhanced:
    'https://support.google.com/google-ads/answer/9888656?hl=en',
  tipsPopover: {
    title: 'ヒント',
    content:
      'よくある質問に関してはこちら：<list><ga>Google Analytics（データ分析）設定</ga><gaECommerce>Google Analytics Eコマース設定</gaECommerce><gtm>Google コード管理ツール</gtm><gAds>Google Ads 広告追跡コード設定</gAds><gRemarketing>Google リマーケティング追跡コード</gRemarketing><bing>Bing コンバージョン追跡</bing><yahoo>Yahoo 広告コード追跡</yahoo><metaPixel>Metaピクセル（新規）</metaPixel><line>LINE 広告追跡コード（LAP）設定</line><ga4>Google Analytics 4（GA4）追跡コード設定</ga4><linePoints>LINE POINTS タスク広告（CPA）追跡設定</linePointslinePoints></list>',
    links: {
      ga: `$t(docLink, {"context": "${trackerKinds.GA}"})`,
      gaECommerce: '$t(docLink, {"context": "gaECommerce"})',
      gtm: `$t(docLink, {"context": "${trackerKinds.GTM}"})`,
      gAds: `$t(docLink, {"context": "${trackerKinds.G_ADS}"})`,
      gRemarketing: `$t(docLink, {"context": "${trackerKinds.G_REMARKETING}"})`,
      bing: `$t(docLink, {"context": "${trackerKinds.BING}"})`,
      yahoo: `$t(docLink, {"context": "${trackerKinds.YAHOO}"})`,
      metaPixel: `$t(docLink, {"context": "${trackerKinds.META}"})`,
      line: `$t(docLink, {"context": "${trackerKinds.LINE}"})`,
      linePoints: `$t(docLink, {"context": "${trackerKinds.LINE_POINTS}"})`,
      ga4: `$t(docLink, {"context": "${trackerKinds.GA4}"})`,
    },
  },
};
