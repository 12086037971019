import * as trackerKinds from 'constants/trackerKinds';
import * as trackerEvents from 'constants/trackerEvents';
import * as trackerConversions from 'constants/trackerConversions';

export const tracker = {
  ID: 'ID',
  [`ID_${trackerKinds.CUSTOM}`]: 'カスタム追跡ツール名',
  displayKind: '不明な追跡者',
  [`displayKind_${trackerKinds.BING}`]:
    'Bing キーワード広告トラッキング コード',
  [`displayKind_${trackerKinds.CRITEO}`]: 'Criteo',
  [`displayKind_${trackerKinds.GA}`]: 'Google Analytics',
  [`displayKind_${trackerKinds.GA4}`]: 'Google Analytics 4',
  [`displayKind_${trackerKinds.G_ADS}`]: 'Google Ads',
  [`displayKind_${trackerKinds.G_REMARKETING}`]: 'Googleのリマーケティング',
  [`displayKind_${trackerKinds.GTM}`]: 'Googleタグ管理ツール',
  [`displayKind_${trackerKinds.LINE}`]: 'LINE Tag (LAP)',
  [`displayKind_${trackerKinds.LINE_POINTS}`]:
    'LINEポイント広告トラッキングコード（CPA）',
  [`displayKind_${trackerKinds.META}`]: 'メタ ピクセル (新規)',
  [`displayKind_${trackerKinds.YAHOO}`]:
    'Yahoo! ネイティブ広告トラッキング コード',
  [`displayKind_${trackerKinds.TIKTOK}`]: 'TikTok Pixel',
  [`displayKind_${trackerKinds.CUSTOM}`]: 'カスタマイズ',
  [`displayKind_${trackerKinds.LEGACY_FB_PIXEL}`]:
    'Facebook 広告コンバージョン ピクセル (旧)',
  [`displayKind_${trackerKinds.LEGACY_FB_AUDIENCE}`]:
    'Facebook カスタム広告オーディエンス ピクセル (旧)',
  displayEvent: '未知のイベント',
  displayEvent_others: 'イベントを追加する',
  [`displayEvent_${trackerEvents.LOADED_ANY_PAGE}`]:
    '顧客はストア内の任意のページにアクセスします',
  [`displayEvent_${trackerEvents.LOADED_HOME_PAGE}`]:
    '顧客がオンラインストアのホームページにアクセス',
  [`displayEvent_${trackerEvents.ADDED_PRODUCT_TO_CART}`]:
    '顧客がショッピングカートに商品を追加する',
  [`displayEvent_${trackerEvents.LOADED_CHECKOUT_PAGE}`]:
    '顧客がチェックアウトページにアクセスする',
  [`displayEvent_${trackerEvents.PLACED_AN_ORDER}`]: '顧客が注文を完了する',
  [`displayEvent_${trackerEvents.SIGNUP_AS_MEMBER}`]: 'お客様が会員登録する',
  [`displayEvent_${trackerEvents.LOADED_CART_PAGE}`]:
    '顧客が訪問するカートページ',
  [`displayEvent_${trackerEvents.LOADED_JUST_FOR_CHECKOUT_PAGE}`]:
    '顧客が訪問する入力情報ページ',
  displayEvent_loadedCardOrCheckoutPage:
    '顧客が訪問するカートページおよび決済ページ',
  html: 'カスタムイベント追跡HTMLコード',
  field: {
    trackerKind: '使用したい追跡ツールは次のとおりです。',
    trackerEvents: '追跡したいイベントは次のとおりです。',
    code: '不明なコード',
    [`code_${trackerKinds.BING}`]:
      '私の Bing 広告コンバージョン ピクセル トラッキング番号は次のとおりです。',
    [`code_${trackerKinds.CRITEO}`]: '私の Criteo ID は次のとおりです。',
    [`code_${trackerKinds.GA}`]:
      '私の Google Analytics 追跡番号は次のとおりです。',
    [`code_${trackerKinds.GA4}`]:
      '私の Google Analytics 4 追跡番号は次のとおりです。',
    [`code_${trackerKinds.G_ADS}`]:
      'Google 広告のコンバージョン数は次のとおりです。',
    [`code_${trackerKinds.G_REMARKETING}`]:
      '私の Google リマーケティング追跡番号は次のとおりです。',
    [`code_${trackerKinds.GTM}`]:
      '私の Google タグ マネージャーの追跡番号は次のとおりです。',
    [`code_${trackerKinds.LINE}`]:
      'LINE 広告タグのコンバージョン数は次のとおりです。',
    [`code_${trackerKinds.LINE_POINTS}`]:
      '私のLINE ポイントタグのコンバージョン数は次のとおりです。',
    [`code_${trackerKinds.YAHOO}`]:
      '私のYahoo!広告プロジェクト番号は次のとおりです。',
    [`code_${trackerKinds.META}`]: '私のメタ ピクセル ID は次のとおりです。',
    [`code_${trackerKinds.CUSTOM}`]:
      '私のカスタム イベント トラッカーの名前は次のとおりです。',
    [trackerKinds.G_ADS]: {
      isShopline: 'ショップラインの所有権の設定',
      code2: 'Google 広告のコンバージョン タグは次のとおりです。',
      enhancedConversions:
        'Google コンバージョン トラッキングを有効にして拡張コンバージョン機能をサポートするかどうか',
    },
    [trackerKinds.GA]: {
      enableEcommerce: 'Google アナリティクス - 電子商取引',
    },
    [trackerKinds.GA4]: {
      enhancedEC: 'Google Analytics 4 - eコマースイベントの測定の強化',
    },
    [trackerKinds.G_REMARKETING]: {
      isShopline: 'ショップラインの所有権の設定',
      dynamic: 'Google動的リマーケティング広告',
      useUniqueId: 'システムに依存しない製品番号または品目番号を使用しますか?',
      singleVariation:
        '個別の製品仕様を表示しますか? それともすべての製品仕様を表示しますか?',
    },
    [trackerKinds.META]: {
      useUniqueId: 'システムに依存しない製品番号または品目番号を使用しますか?',
      singleVariation:
        '個別の製品仕様を表示しますか? それともすべての製品仕様を表示しますか?',
    },
    [trackerKinds.YAHOO]: {
      code2: '私のYahoo!広告追跡番号は次のとおりです。',
    },
    [trackerKinds.CUSTOM]: {
      html: '私の $t(html) は次のとおりです。',
    },
    [trackerKinds.LINE_POINTS]: {
      missions: '追跡したいアクティビティは次のとおりです。',
      intercom:
        'LINE ポイント広告の導入をご希望の場合は、<intercom>SHOPLINE オンライン コンサルタント</intercom> まで直接ご連絡ください。担当者を派遣いたします。',
    },
  },
  label: {
    events:
      '追跡イベントを次のように設定することをお勧めします: 顧客がオンライン ストアの任意のページにアクセスしたとき',
    events_disabled:
      'この追跡ツールは、各 Web ページに対応するイベントを自動的に埋め込み/追跡するのに役立ちます。',
    [trackerKinds.G_ADS]: {
      isShopline: '読み取り専用として設定',
      enhancedConversions:
        'Google コンバージョン トラッキングを有効にして拡張コンバージョンをサポートする',
    },
    [trackerKinds.GA]: {
      enableEcommerce: '電子商取引を有効にする',
    },
    [trackerKinds.GA4]: {
      enhancedEC: 'GA4 バックエンドに移動して有効にします',
    },
    [trackerKinds.G_REMARKETING]: {
      isShopline: '読み取り専用として設定',
      dynamic: '動的リマーケティング広告を有効にする',
      useUniqueId: '',
      useUniqueId_productId: 'システムに依存しない製品番号を使用する',
      useUniqueId_sku: '製品番号を使用する',
      singleVariation: '',
      singleVariation_showAll: 'すべての製品仕様を表示',
      singleVariation_showOne: '個別の製品仕様を表示',
    },
    [trackerKinds.META]: {
      useUniqueId: '',
      useUniqueId_productId: 'システムに依存しない製品番号を使用する',
      useUniqueId_sku: '製品番号を使用する',
      singleVariation: '',
      singleVariation_showAll: 'すべての製品仕様を表示',
      singleVariation_showOne: '個別の製品仕様を表示',
    },
    [trackerKinds.LINE_POINTS]: {
      missions: '',
      [`missions_${trackerConversions.PLACED_AN_ORDER}`]:
        'ショッピングガイドのタスク',
    },
  },
  placeholder: {
    trackerEvents: '請選擇',
    code: '',
    [`code_${trackerKinds.BING}`]: '例: 1234567',
    [`code_${trackerKinds.CRITEO}`]: '例: 123456',
    [`code_${trackerKinds.GA}`]: '例: UA-000000-01',
    [`code_${trackerKinds.GA4}`]: '例: G-XXXXXXX',
    [`code_${trackerKinds.G_ADS}`]: '例: 888888888',
    [`code_${trackerKinds.G_REMARKETING}`]: '例: 1234567890',
    [`code_${trackerKinds.GTM}`]: '例: GTM-XXXX',
    [`code_${trackerKinds.LINE}`]: '例: 888888888',
    [`code_${trackerKinds.LINE_POINTS}`]: '例: FREECOINS_0000',
    [`code_${trackerKinds.YAHOO}`]: '例: 12345',
    [`code_${trackerKinds.META}`]: '例: 1234567890',
    [trackerKinds.GA4]: {
      enhancedEC:
        '拡張測定では、Google Analytics 4 インターフェースのオプション (イベント) を有効にして、コンテンツとのユーザー インタラクションを測定できます。有効にすると、Web サイト データは、閲覧イベント、Web ページ垂直スクロール イベント、外部ドメイン クリック イベント、オンサイト検索イベント、Web サイト埋め込みビデオ参加イベント、ファイル ダウンロード イベントなどのイベントに関連してストリーミングできます。',
    },
    [trackerKinds.G_ADS]: {
      code2: '例: aBC_dOe1fghIj2k3lmN',
      enhancedConversions:
        'Google 広告のコンバージョン測定の精度を向上させるために、拡張コンバージョンを有効にすることをおすすめします。 Google は、安全なチャネルを使用して、より多くのウェブサイトの動作やイベント データを含む強化されたコンバージョン データをサーバーから Google に送信できるように支援します。これにより、Google 広告広告の効果をより深く測定し、配信をより正確にすることができます。さらに、Web サイトのデータ送信を保護するために、「オンライン ストアのデザイン > デザイン > 詳細なデザイン設定」に移動して、Cookie 認証プロンプト機能を有効にすることをお勧めします。サードパーティのサービスを選択する前に、法務チームに相談してプライバシー規制を確実に遵守することも非常に重要です。 <br /><lk>詳細</​​lk>',
    },
    [trackerKinds.YAHOO]: {
      code2: '例: 123456',
    },
    [trackerKinds.CUSTOM]: {
      html: '<script></script>',
    },
  },
  fieldValidation: {
    code: {
      pattern: '入力できる文字は、半角英数字、-、_のみです。',
    },
    events: {
      custom: '少なくとも 1 つのイベントを選択してください',
    },
  },
  link: {
    [trackerKinds.GA4]: {
      enhancedEC:
        'https://support.google.com/analytics/answer/9216061?hl=zh-Hant',
    },
  },
  tipsPopover: {
    title: 'ヒント',
    content:
      'よくある質問については、<list><ga>Google アナリティクスの設定方法</ga><gaECommerce>Google アナリティクスの e コマース設定の有効化</gaECommerce></list>をご覧ください。',
    links: {
      ga: `$t(main:docLink, {"context": "${trackerKinds.GA}"})`,
      gaECommerce: '$t(main:docLink, {"context": "gaECommerce"})',
    },
  },
};
