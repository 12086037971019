import { TIKTOK } from 'constants/trackerKinds';

export const tt4bPopup = {
  title: `$t(tracker:displayKind, {"context": "${TIKTOK}"})`,
  content:
    'TikTok Pixelは「TikTok Business Extension Kit」をインストールして入手する必要があります。インストールしますか?',
  content_edit:
    '「サードパーティサービス > TikTok」の「アセットの編集」をクリックして、TikTok ピクセルを編集しますか?',
  content_remove:
    'TikTok ピクセルを削除するには、「サードパーティ サービス > TikTok」のインストールをキャンセルします。キャンセルしますか?',
  ok: 'インストールに進む',
  ok_edit: '編集に移動',
  ok_remove: 'キャンセルに進む',
  cancel: 'まだ',
};
