import { META } from 'constants/trackerKinds';

export const fbEntrancePopup = {
  title: `$t(tracker:displayKind, {"context": "${META}"})`,
  content:
    'メタピクセルは「Facebook Business Extension Package」をインストールすることで取得する必要があります。インストールしますか?',
  content_edit:
    '「Facebook Business Extension」の「Edit Assets」をクリックして Facebook ピクセルを編集しますか?',
  content_remove:
    'Facebook Business Extension をアンインストールすると、Facebook ピクセルが削除されます。キャンセルしますか?',
  ok: 'インストールに進む',
  ok_edit: '編集に移動',
  ok_remove: 'キャンセルに進む',
  cancel: 'まだ',
};
